import React, { useState } from 'react';
import {Flex, Box} from 'rebass'
import ListSims from './Similars';
import 'rc-slider/assets/index.css';
import { Button } from '@material-ui/core';
import ExpSlider from './Slider';
import {Redirect} from 'react-router-dom';
import {baseurl} from '../../config';

export default function Experiment(props) {

    const [redirect, setRedirect] = useState(false);

    const [body, setBody] = useState(5);
    const [sweetness, setSweetness] = useState(5);
    const [honey, setHoney] = useState(5);
    const [floral, setFloral] = useState(5);
    const [fruity, setFruity] = useState(5);
    const [winey, setWiney] = useState(5);
    const [peaty, setPeaty] = useState(5);
    const [medicinal, setMedicinal] = useState(5);
    const [tobacco, setTobacco] = useState(5);
    const [nutty, setNutty] = useState(5);
    const [malty, setMalty] = useState(5);
    const [spicy, setSpicy] = useState(5);
    const [wood, setWood] = useState(5);
  
    const [KNN, setKNN] = useState([]);
  
    const flavors = [body, sweetness, honey, floral, fruity, winey, peaty, medicinal, tobacco, nutty, malty, spicy, wood]
  
    async function postDataKNN(data = {}) {
      // Default options are marked with *
      const response = await fetch(baseurl+`/knn`, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      const output = await response.json(); // parses JSON response into native JavaScript objects
      setKNN(output);
      console.log(output)
    }

    if (redirect === true) {
      return <Redirect to="/Search" />
    }

    return (
      <div>
      
      <div>
      <Flex width={1} sx={{justifyContent:'center'}}>
  
        <Box sx={{pb:'15px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
            <Flex backgroundColor="#022140">
              <Box  width={1} height={50} pl={5} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 32}}>
                Experiment
              </Box>
            </Flex>
  
        <Box sx={{px:"40px"}}>
        <Box m='20px' fontFamily="arial black">Adjust the sliders below to find whiskeys with your preferred flavor profile. Press "DRINK" and then scroll down to see your results.</Box>
        <Box as='hr' sx={{mb:'15px', bg: 'gray', border: 0, height: 1}}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Body
        </Box>
        <ExpSlider onChangeFunc={setBody}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Sweetness
        </Box>
        <ExpSlider onChangeFunc={setSweetness}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Honey/Vanilla
        </Box>
        <ExpSlider onChangeFunc={setHoney}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Floral/Herbal
        </Box>
        <ExpSlider onChangeFunc={setFloral}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Fruity
        </Box>
        <ExpSlider onChangeFunc={setFruity}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Winey/Sherry
        </Box>
        <ExpSlider onChangeFunc={setWiney}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Peaty/Smokey
        </Box>
        <ExpSlider onChangeFunc={setPeaty}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Medicinal/Salty
        </Box>
        <ExpSlider onChangeFunc={setMedicinal}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Tobacco/Leather
        </Box>
        <ExpSlider onChangeFunc={setTobacco}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Nutty/Creamy
        </Box>
        <ExpSlider onChangeFunc={setNutty}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Malty
        </Box>
        <ExpSlider onChangeFunc={setMalty}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Spicy
        </Box>
        <ExpSlider onChangeFunc={setSpicy}/>
  
        <Box textAlign='center' fontFamily='arial black'>
          Wood/Cask
        </Box>
        <ExpSlider onChangeFunc={setWood}/>

      </Box>
  
      <Button onClick={e => postDataKNN({userInputs:flavors})} style={{height:"80px", width:"25%", marginTop:"20px", backgroundColor:'#022140', color:'white', fontFamily:'arial black', fontSize:32, borderWidth: '3px', borderStyle: 'solid', borderColor: 'black', borderRadius: '10px 10px 10px 10px'}}>DRINK</Button>
      </Box>
  
      </Flex>
      </div>
  
  
      {Object.entries(KNN).length > 0 && (
      <div>
      <Box sx={{pb:'15px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
          <Flex backgroundColor="#022140">
            <Box  width={1} height={50} pl={5} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 32}}>
              Your Ideal Whiskies
            </Box>
          </Flex>
          <ListSims setQuery={props.setQuery} setRedirect={setRedirect} similars={KNN.knn_sims}/>
        </Box>
      </div>
      )}
  
      </div>
    );
  }