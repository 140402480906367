import React, { Component } from "react";
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';
import {Flex} from 'rebass'

const marks = {
    0:'0',
    0.5:'0.5',
    1:'1',
    1.5:'1.5',
    2:'2',
    2.5:'2.5',
    3:'3',
    3.5:'3.5',
    4:'4',
    4.5:'4.5',
    5:'5',
    5.5:'5.5',
    6:'6',
    6.5:'6.5',
    7:'7',
    7.5:'7.5',
    8:'8',
    8.5:'8.5',
    9:'9',
    9.5:'9.5',
    10:'10',
};

class ExpSlider extends Component {

    constructor(props) {
        super(props);
        this.state = {
          value: 5,
        };
    };

    onSliderChange = (value) => {
        console.log(value);
        this.setState({
          value,
        });
        this.props.onChangeFunc(value)
    };

    render() {

        return(
        <div>
        <Flex height={50} marginTop={"5px"} fontFamily="arial black">
        <Slider 
            value={this.state.value}
            onChange={this.onSliderChange}
            marks={marks} min={0} max={10} step={0.5}
            defaultValue={5}
            trackStyle={{backgroundColor: '#b00020', height: 10 }}
            railStyle={{ backgroundColor: 'gray', height: 10 }}
            handleStyle={{
                border: "0",
                cursor: "pointer",
                borderColor: 'blue',
                height: 20,
                width: 20,
                marginTop: -6,
                backgroundColor: 'black',
            }}
            dotStyle={{
                border: "0",
                height: 0,
                width: 0,
                backgroundColor: 'black',
            }}
             />
        </Flex>
        </div>
    )};
}

export default ExpSlider;