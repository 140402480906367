import React, { PureComponent } from 'react';
import {
  BarChart, ResponsiveContainer, Bar, XAxis, YAxis, CartesianGrid
} from 'recharts';

const categories = ["Body","Sweetness","Honey/Vanilla","Floral/Herbal","Fruity","Winey/Sherry","Peaty/Smoky","Medicinal/Salty","Tobacco/Leather","Nutty/Creamy","Malty","Spicy","Wood/Cask"]

  class CustomizedAxisTick extends PureComponent {
    render() {
      const {
        x, y, payload,
      } = this.props;
  
      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} textAnchor="end" transform="rotate(-45)">{payload.value}</text>
        </g>
      );
    }
  }

export default class Bar2 extends PureComponent {

  render() {
    return (
      <div style={{ fontSize:20, fontFamily: "arial black", width: '100%', height: 500 }}>
      <ResponsiveContainer>
      <BarChart
        data={
          [
            {name: categories[0], value: this.props.x[0]},
            {name: categories[1], value: this.props.x[1]},
            {name: categories[2], value: this.props.x[2]},
            {name: categories[3], value: this.props.x[3]},
            {name: categories[4], value: this.props.x[4]},
            {name: categories[5], value: this.props.x[5]},
            {name: categories[6], value: this.props.x[6]},
            {name: categories[7], value: this.props.x[7]},
            {name: categories[8], value: this.props.x[8]},
            {name: categories[9], value: this.props.x[9]},
            {name: categories[10], value: this.props.x[10]},
            {name: categories[11], value: this.props.x[11]},
            {name: categories[12], value: this.props.x[12]},
            ]
        }
        margin={{
          top: 10, right: 10, left: 20, bottom: 120,
        }}
      >
        <CartesianGrid stroke="#8D8D8D" vertical={false} />
        <XAxis tickMargin={15} tickLine={false} interval={0} dataKey="name" tick={<CustomizedAxisTick />} />
        <YAxis interval={0} ticks={[1,2,3,4,5,6,7,8,9,10]} hide={true} domain={[0, 10]} />
        <Bar dataKey="value" fill="#b00020" animationDuration={3000} />
      </BarChart>
      </ResponsiveContainer>
      </div>
    );
  }
}