import React, { useState } from 'react';
import './App.css';
import NavBar from './Components/Desktop/Header';
import Scatter from './Components/Desktop/Scatter';
import Search from './Components/Desktop/Search';
import Table from './Components/Desktop/Table';
import PDF from './Components/Desktop/PDF';
import About from './Components/Desktop/About';
import Experiment from './Components/Desktop/Experiment';
import NavBar2 from './Components/Mobile/Header';
import Search2 from './Components/Mobile/Search';
import About2 from './Components/Mobile/About';
import Experiment2 from './Components/Mobile/Experiment';
import {Flex, Box} from 'rebass'
import {BrowserRouter, Route, Redirect} from 'react-router-dom'

function App () {
  
  const [query, setQuery] = useState('');
  const isMobile = window.innerWidth <= 1100;

  if (!isMobile){
  return (
    <BrowserRouter>
    
    <div className="App">

      <NavBar/>

      <Flex 
      width={1}
      height={10}
      backgroundColor="black">
      <Route path="/Search">
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
      </Route>
      <Route path="/Index">
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
      </Route>
      <Route path="/3DWhiskeyMap">
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
      </Route>
      <Route path="/Experiment">
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
      </Route>
      <Route path="/TastingSheet">
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
      </Route>
      <Route path="/About">
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="black"></Box>
        <Box width={1/6} height={10} backgroundColor="#b00020"></Box>
      </Route>
      </Flex>

      <div style={{margin:"15px"}}>

      <Route path="/Search">
        <Search setQuery={setQuery} query={query}/>
      </Route>
      <Route path="/Search2" render={() => {return <Redirect to='/Search' />}} />
      <Route path="/Index" component={Table} />
      <Route path="/3DWhiskeyMap" component={Scatter} />
      <Route path="/Experiment">
        <Flex width={1} justifyContent='center'>
          <Experiment setQuery={setQuery} />
        </Flex>
      </Route>
      <Route path="/TastingSheet" component={PDF} />
      <Route path="/About" component={About} />

      </div>
    </div>

    </BrowserRouter>
  );
  }

  if (isMobile){
    return (
      <BrowserRouter>
      
      <div className="App">
  
        <NavBar2/>
  
        <Flex 
        width={1}
        height={10}
        backgroundColor="black">
        <Route path="/Search">
          <Box width={1/3} height={10} backgroundColor="#b00020"></Box>
          <Box width={1/3} height={10} backgroundColor="black"></Box>
          <Box width={1/3} height={10} backgroundColor="black"></Box>
        </Route>
        <Route path="/Experiment">
          <Box width={1/3} height={10} backgroundColor="black"></Box>
          <Box width={1/3} height={10} backgroundColor="#b00020"></Box>
          <Box width={1/3} height={10} backgroundColor="black"></Box>
        </Route>
        <Route path="/About">
          <Box width={1/3} height={10} backgroundColor="black"></Box>
          <Box width={1/3} height={10} backgroundColor="black"></Box>
          <Box width={1/3} height={10} backgroundColor="#b00020"></Box>
        </Route>
        </Flex>
  
        <div style={{margin:"15px"}}>
        <p style={{fontFamily:'arial black', fontSize:"14px"}}>Please visit on desktop to see all features.</p>
        <Route path="/Search">
          <Search2 setQuery={setQuery} query={query}/>
        </Route>
        <Route path="/Search2" render={() => {return <Redirect to='/Search' />}} />
        <Route path="/Experiment">
          <Flex width={1} justifyContent='center'>
            <Experiment2 setQuery={setQuery} />
          </Flex>
        </Route>
        <Route path="/About" component={About2} />
  
        </div>
      </div>
  
      </BrowserRouter>
    );
    }

}

export default App;
