import React, { Component } from "react";
import Plot from 'react-plotly.js';
import {Flex, Box} from 'rebass';
import {baseurl} from '../../config';

class Scatter extends Component {

  state = {data:null};

  sendData = async () => {
    try {
      const response = await fetch(baseurl+`/whiskey_scatter`);
      const output = await response.json();
      this.setState({data:output});
      console.log(output);
    } catch (error) {
      console.log(error);
    };
  };

  render() {

    if (!this.state.data){
      this.sendData();
    }
    else{

    var whiskeys = this.state.data.whiskey
    var types = this.state.data.type
    var x = this.state.data.Component_1
    var y = this.state.data.Component_2
    var z = this.state.data.Component_3
    var trace1 = {
      x: x, y: y, z: z,
      mode: 'markers',
      text: whiskeys,
      hoverinfo:"text",
      transforms: [{
        type: 'groupby',
        groups: types,
      }],
      marker: {
        size: 12,
        opacity: 0.8},
        type: 'scatter3d',
        showlegend: true
    };
    var data= trace1;
    var layout = {
      legend: {
        x: 1, 
        y: 0.5,
        bgcolor: "#BDBDBD"
      },
      width: 1080,
      height: 1000,
      paper_bgcolor: '#BDBDBD',
      font: {
        family: 'arial black',
        size: 16,
        color: 'black'
      },
      scene: {
        xaxis:{title: 'Component 1', range: [0, 1], showbackground: true, showticklabels: false, backgroundcolor: "#E0E0E0", gridcolor: "black"},
        yaxis:{title: 'Component 2', range: [0, 1], showbackground: true, showticklabels: false, backgroundcolor: "#E0E0E0", gridcolor: "black"},
        zaxis:{title: 'Component 3', range: [0, 1], showbackground: true, showticklabels: false, backgroundcolor: "#E0E0E0", gridcolor: "black"},
        aspectmode: 'manual',
        aspectratio: {
          x: 1, y: 1, z: 1,
         },  
      },
      margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0
      }
    };
  }
    return (
      <Box sx={{pb:'15px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
        <Flex backgroundColor="#022140">
          <Box  width={1} height={50} pl={5} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 32}}>
            3D Whiskey Map
          </Box>
        </Flex>
        <Box m='20px' fontFamily="arial black">The 3D graph may take a moment to load.<br/>Click and drag to rotate the graph. Use the legend to select or deselect types. See the "About" section for more information.</Box>

      <div>
        {this.state.data && (<Plot
        data={[data]}
        layout={layout}
      />)}
      </div>

    </Box>
    );
  }
}

export default Scatter;