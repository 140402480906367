import React from 'react';
import {Link,Box,Flex} from 'rebass';

export default function ListSims(props) {
    
    const similars = props.similars
    
    return (
        <div>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'
                color='black'
                bg='F1F1F2'>
                <p><b>1. </b><Link href="#" onClick={e => {props.setQuery(similars[1]); props.setRedirect(true)}} fontWeight='bold'>{similars[1]}</Link>{" ("+Math.round(similars[0]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>11. </b><Link href="#" onClick={e => {props.setQuery(similars[21]); props.setRedirect(true)}} fontWeight='bold'>{similars[21]}</Link>{" ("+Math.round(similars[20]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>2. </b><Link href="#" onClick={e => {props.setQuery(similars[3]); props.setRedirect(true)}} fontWeight='bold'>{similars[3]}</Link>{" ("+Math.round(similars[2]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>12. </b><Link href="#" onClick={e => {props.setQuery(similars[23]); props.setRedirect(true)}} fontWeight='bold'>{similars[23]}</Link>{" ("+Math.round(similars[22]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>3. </b><Link href="#" onClick={e => {props.setQuery(similars[5]); props.setRedirect(true)}} fontWeight='bold'>{similars[5]}</Link>{" ("+Math.round(similars[4]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>13. </b><Link href="#" onClick={e => {props.setQuery(similars[25]); props.setRedirect(true)}} fontWeight='bold'>{similars[25]}</Link>{" ("+Math.round(similars[24]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>4. </b><Link href="#" onClick={e => {props.setQuery(similars[7]); props.setRedirect(true)}} fontWeight='bold'>{similars[7]}</Link>{" ("+Math.round(similars[6]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>14. </b><Link href="#" onClick={e => {props.setQuery(similars[27]); props.setRedirect(true)}} fontWeight='bold'>{similars[27]}</Link>{" ("+Math.round(similars[26]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>5. </b><Link href="#" onClick={e => {props.setQuery(similars[9]); props.setRedirect(true)}} fontWeight='bold'>{similars[9]}</Link>{" ("+Math.round(similars[8]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>15. </b><Link href="#" onClick={e => {props.setQuery(similars[29]); props.setRedirect(true)}} fontWeight='bold'>{similars[29]}</Link>{" ("+Math.round(similars[28]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>6. </b><Link href="#" onClick={e => {props.setQuery(similars[11]); props.setRedirect(true)}} fontWeight='bold'>{similars[11]}</Link>{" ("+Math.round(similars[10]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>16. </b><Link href="#" onClick={e => {props.setQuery(similars[31]); props.setRedirect(true)}} fontWeight='bold'>{similars[31]}</Link>{" ("+Math.round(similars[30]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>7. </b><Link href="#" onClick={e => {props.setQuery(similars[13]); props.setRedirect(true)}} fontWeight='bold'>{similars[13]}</Link>{" ("+Math.round(similars[12]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>17. </b><Link href="#" onClick={e => {props.setQuery(similars[33]); props.setRedirect(true)}} fontWeight='bold'>{similars[33]}</Link>{" ("+Math.round(similars[32]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>8. </b><Link href="#" onClick={e => {props.setQuery(similars[15]); props.setRedirect(true)}} fontWeight='bold'>{similars[15]}</Link>{" ("+Math.round(similars[14]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>18. </b><Link href="#" onClick={e => {props.setQuery(similars[35]); props.setRedirect(true)}} fontWeight='bold'>{similars[35]}</Link>{" ("+Math.round(similars[34]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>9. </b><Link href="#" onClick={e => {props.setQuery(similars[17]); props.setRedirect(true)}} fontWeight='bold'>{similars[17]}</Link>{" ("+Math.round(similars[16]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'    
                color='black'
                bg='F1F1F2'>
                <p><b>19. </b><Link href="#" onClick={e => {props.setQuery(similars[37]); props.setRedirect(true)}} fontWeight='bold'>{similars[37]}</Link>{" ("+Math.round(similars[36]*100)+"% match)"}</p>
            </Box>
            </Flex>
            <Flex mb="0px">
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'  
                color='black'
                bg='F1F1F2'>
                <p><b>10. </b><Link href="#" onClick={e => {props.setQuery(similars[19]); props.setRedirect(true)}} fontWeight='bold'>{similars[19]}</Link>{" ("+Math.round(similars[18]*100)+"% match)"}</p>
            </Box>
            <Box
                width={1/2}
                textAlign='left'
                pl={1}
                fontFamily='arial black'
                fontSize='12px'  
                color='black'
                bg='F1F1F2'>
                <p><b>20. </b><Link href="#" onClick={e => {props.setQuery(similars[39]); props.setRedirect(true)}} fontWeight='bold'>{similars[39]}</Link>{" ("+Math.round(similars[38]*100)+"% match)"}</p>
            </Box>
            </Flex>
        </div>
    );
}