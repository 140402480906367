import React from 'react';
import {Box,Flex} from 'rebass'
import RouterLink from './Link'

export default function NavBar() {
    return (
        <div>
    <Flex
        width={1}
        height={55}
        px={3}
        backgroundColor="black"
        color='white'
        fontFamily='verdana'
        alignItems='center'
        justifyContent='center'>
        <Box p={2} margin='3' fontSize='5' color="white" sx={{textDecoration: 'none', fontWeight: 'bold'}}>
            WhiskeyMapper.com
        </Box>
    </Flex>

    <Flex
        width={1}
        height={40}
        px={0}
        backgroundColor="black"
        color='white'
        fontFamily='verdana'
        alignItems='center'>
        <Box mx='auto' />
        <RouterLink to="/Search">
            Search
        </RouterLink>
        <RouterLink to="/Index">
            Index
        </RouterLink>
        <RouterLink to="/3DWhiskeyMap">
            3D Whiskey Map
        </RouterLink>
        <RouterLink to="/Experiment">
            Experiment
        </RouterLink>
        <RouterLink to="/TastingSheet">
            Tasting Sheet
        </RouterLink>
        <RouterLink to="/About">
            About
        </RouterLink>
    </Flex>

    </div>
    
    );
}