import React from 'react';
import {Box,Flex} from 'rebass'

export default function ListDesc(props) {
    
    const words = props.words

    return (
    <div>
    <Flex>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"1. "+words[0].charAt(0).toUpperCase() + words[0].substring(1)}<font size="3"> ({words[20].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"6. "+words[5].charAt(0).toUpperCase() + words[5].substring(1)}<font size="3"> ({words[25].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"11. "+words[10].charAt(0).toUpperCase() + words[10].substring(1)}<font size="3"> ({words[30].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"16. "+words[15].charAt(0).toUpperCase() + words[15].substring(1)}<font size="3"> ({words[35].toFixed(2)})</font>
    </Box>
    </Flex>
    <Flex>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"2. "+words[1].charAt(0).toUpperCase() + words[1].substring(1)}<font size="3"> ({words[21].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"7. "+words[6].charAt(0).toUpperCase() + words[6].substring(1)}<font size="3"> ({words[26].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"12. "+words[11].charAt(0).toUpperCase() + words[11].substring(1)}<font size="3"> ({words[31].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"17. "+words[16].charAt(0).toUpperCase() + words[16].substring(1)}<font size="3"> ({words[36].toFixed(2)})</font>
    </Box>
    </Flex>
    <Flex>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"3. "+words[2].charAt(0).toUpperCase() + words[2].substring(1)}<font size="3"> ({words[22].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"8. "+words[7].charAt(0).toUpperCase() + words[7].substring(1)}<font size="3"> ({words[27].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"13. "+words[12].charAt(0).toUpperCase() + words[12].substring(1)}<font size="3"> ({words[32].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"18. "+words[17].charAt(0).toUpperCase() + words[17].substring(1)}<font size="3"> ({words[37].toFixed(2)})</font>
    </Box>
    </Flex>
    <Flex>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"4. "+words[3].charAt(0).toUpperCase() + words[3].substring(1)}<font size="3"> ({words[23].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"9. "+words[8].charAt(0).toUpperCase() + words[8].substring(1)}<font size="3"> ({words[28].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"14. "+words[13].charAt(0).toUpperCase() + words[13].substring(1)}<font size="3"> ({words[33].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"19. "+words[18].charAt(0).toUpperCase() + words[18].substring(1)}<font size="3"> ({words[38].toFixed(2)})</font>
    </Box>
    </Flex>
    <Flex>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'>
        {"5. "+words[4].charAt(0).toUpperCase() + words[4].substring(1)}<font size="3"> ({words[24].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"10. "+words[9].charAt(0).toUpperCase() + words[9].substring(1)}<font size="3"> ({words[29].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"15. "+words[14].charAt(0).toUpperCase() + words[14].substring(1)}<font size="3"> ({words[34].toFixed(2)})</font>
    </Box>
    <Box
        p={2}
        width={1/4}
        textAlign='left'
        pl={5}
        fontFamily='arial black'
        fontSize='4' 
        fontWeight='bold'
        color='black'
        >
        {"20. "+words[19].charAt(0).toUpperCase() + words[19].substring(1)}<font size="3"> ({words[39].toFixed(2)})</font>
    </Box>
    </Flex>
    </div>
    );
}