import React from "react";
import pdf from '../Tasting_Sheet.pdf';
import {Flex, Box} from 'rebass';

export default function PDF(){
    return (
        <div>
            <Box sx={{pb:'15px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
                <Flex backgroundColor="#022140">
                    <Box  width={1} height={50} pl={5} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 32}}>
                    Tasting Sheet
                    </Box>
                </Flex>
                <Box my={50}>
                <a href={pdf} target="_blank" style={{fontFamily:"arial black", fontSize: 32}}>Click here to download the WhiskeyMapper.com tasting sheet.</a>
                </Box>
            </Box>
        </div>
    )
}