import React from 'react'
import MUIDataTable from "mui-datatables";
import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import {Flex, Box} from 'rebass';
import {baseurl} from '../../config';

class Table extends React.Component {
  
  state = {data:null};

  sendData = async () => {
    try {
      const response = await fetch(baseurl+`/whiskey_table`);
      const output = await response.json();
      this.setState({data:output.table});
      console.log(output);
    } catch (error) {
      console.log(error);
    };
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          paper: {
            boxShadow: "none"
          }
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "white",
            color:'black',
            fontSize:'16px',
            fontFamily: 'arial black',
          }
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: "white",
            color: "black",
            fontFamily: 'arial black'
          }
        },
        MUIDataTablePagination: {
          root: {
            backgroundColor: "white",
            color: "black",
            fontSize:'16px',
            fontFamily: 'arial black',
          }
        },
        MUIDataTableFilterList: {
          root: {
            backgroundColor: "white",
            color: "black",
            margin: '0px 0px 0px 0px'
          }
        },
        MUIDataTableHeadCell: {
          fixedHeader: {
            color:'#000000',
            backgroundColor: "white",
          },
          sortActive: {
            color:'#000000'
          }, 
          sortAction: {
            color: "#000000"
          },
          root: {
            fontWeight:'bold',
            backgroundColor: "white",
            color:'black',
            fontSize:'20px',
            fontFamily: 'arial black',
          },
        },
      }
    });

  render(){
    
    if (!this.state.data){
      this.sendData();
    }

    const columns = [
    {
      name: "whiskey",
      label: "Whiskey",
      options: {
      filter: false,
      sort: true
      }
    },
    {
      name: "reviews",
      label: "Reviews",
      options: {
        filter: false,
        sort: true,
      }
    },
    {
      name: "rating",
      label: "User Rating",
      options: {
      filter: false,
      sort: true,
      sortDirection: "desc" 
      }
    },
    {
      name: "std_dev",
      label: "Standard Deviation",
      options: {
      filter: false,
      sort: true,
      }
    },
    {
      name: "type",
      label: "Type",
      options: {
      filter: true,
      sort: true,
      }
    },
  ];

  const options = {
    selectableRows: 'none',
    print: false,
    viewColumns: false,
    resizableColumns: false,
    rowsPerPage: 50,
    rowsPerPageOptions: [10, 20, 50, 100, 1000],
  };

  return (
    <Box sx={{pb:'15px', my:'20px', backgroundColor:'white', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
      <Flex backgroundColor="#022140">
        <Box  width={1} height={50} pl={5} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 32}}>
          Index Table
        </Box>
      </Flex>
    
    <MuiThemeProvider theme={this.getMuiTheme()}>
      {this.state.data && (
      <MUIDataTable
      title={""}
      data={this.state.data}
      columns={columns}
      options={options}
      />)}
    </MuiThemeProvider>

    </Box>
    )
  }
}

export default Table;