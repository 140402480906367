import React from "react";
import {Flex, Box} from 'rebass';

export default function About2() {
    return (

        <Box sx={{pb:'5px', my:'5px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
            <Flex backgroundColor="#022140">
                <Box  width={1} pl={2} py={2} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 16}}>
                About
                </Box>
            </Flex>
            <Box m="20px" textAlign='left' fontSize="12px" fontFamily="arial black">
                <h2>Mission</h2>
                <p>It's not easy to find great whiskey. And even then, it can be difficult to identify the aromas, flavors, and textures that you're experiencing. WhiskeyMapper makes whiskey more accessible by providing the tools you need to quickly assess and enjoy new whiskeys. WhiskeyMapper is designed to be simple and intuitive, whether you're at home, on the town, or in the liquor store.</p>
                
                <h2>Data</h2>
                <p>Our flavor profiles and recommendation system utilize real reviews from real people on Reddit's Whiskey Network. By using thousands of reviews from hundreds of users, we ensure that our data set isn't biased by any one person's taste buds. A standardized format and thorough reviews are an added bonus. To ensure accuracy, each individual review is trimmed, cleaned, lemmatized, and reduced based on a dictionary of over 300 key descriptive features. Flavor profiles are created for each whiskey based on these reviews which then enable us to run our recommendation engine. Our 13-feature whiskey profiles are based on the work of David Wishart, detailed in his 'Whiskey Classified' book.</p>

                <h2>Recommendation Engine</h2>
                <p>Our recommendation engine utilizes three different models. Only descriptive terms are passed into the models. Information like name, brand, type, region, and age are never considered.<br/>
                <p>1. Content-Based Filtering - All Key Terms. This model calculates the relative frequency of 300+ key descriptive terms for each whiskey. A matrix is created with a new row for each whiskey and a column for each of the key features. We then calculate the similarity of each whiskey to every other whiskey using cosine similarity, which measures the angle between data points projected in a multi-dimensional space.</p>
                <p>2. Content-Based Filtering - Flavor Profiles. The first model does a great job of capturing nuance. However, it can overlook relationships between terms. For example, the terms "smoke" and "campfire" describe similar flavors and this connection  may not be capured in the first model. For this reason, we mapped each of the key terms to one of 13 flavor categories to capture relationships between whiskeys on a higher order. Once the key terms were mapped to general categories, we again calculated cosine similarities between whiskeys.</p>
                <p>3. Item Based Collaborative Filtering. This approach is markedly different from the first two models. Here, we utilize users' ratings of various whiskeys to calculate similarities. If a user assigns very high ratings to Whiskey A and Whiskey B and assigns very low ratings to Whiskey C and Whiskey D, we can infer that each pair of whiskeys had some inherent relationship that drove similar ratings. While this assumption is relatively useless with a small sample size, it can be very powerful with a sufficiently large set of users and whiskeys (thanks, Reddit!). This model also yields cosine similarities, however these similaries were normalized and scaled to be more consistent with the other models.</p></p>

                <h2>Index</h2>
                <p>The Index provides an easy way to search, filter, and sort all of the whiskeys in our database. The data can also be downloaded as a .csv file.</p>

                <h2>3D Whiskey Map</h2>
                <p>The 3D Whiskey Map is produced by collapsing the 300+ descriptive features from Model 1 to three dimensions using principal component analysis. Components 1 and 2 generally capture variations in flavor, whereas Component 3 generally captures maturity/complexity. The whiskeys exhibit an "upside-down pyramid" pattern, with younger whiskeys converging along Components 1 and 2. This phenomenon may be the result of unique regional characteristics becoming more pronounced as whiskeys age.</p>

                <h2>Experiment</h2>
                <p>The Experiment tab allows users to search for whiskeys based on their preferred flavor profile across 13 features. This function utilizes a k-nearest neighbors algorithm to identify whiskeys with the highest cosine similarity to the user's target profile.</p>

                <h2>Tasting Sheet</h2>
                <p>Our tasting sheet provides additional context for how descriptive words are mapped to flavor categories. Make sure to post your reviews on Reddit!</p>

                <h2>Contact</h2>
                <p>WhiskeyMapper is a personal project created by Kevin Mesmer. For questions or feedback, please email <a href="mailto: whiskeymapper@gmail.com">whiskeymapper@gmail.com</a>.</p>

                <h2>Support</h2>
                <p>WhiskeyMapper does not, and never will, feature advertising or sponsorship of any kind. All startup and maintenance costs are paid out-of-pocket by the creator. You can support the effort at <a href="https://www.patreon.com/kevinmesmer">www.patreon.com/kevinmesmer</a>. Your support will go toward server and domain fees and adding new features over time.</p>

            </Box>
        </Box>
    )
}