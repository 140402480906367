import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button } from '@material-ui/core';
import ListDesc from './Descriptive';
import Bar from './Bar';
import ListSims from './Similars';
import {Flex, Box} from 'rebass';
import {Redirect} from 'react-router-dom';
import {baseurl} from '../../config';

export default function Search2(props) {
  
  const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tempQuery, setTempQuery] = useState("");

  const [data, setData] = useState(["Loading..."]);
  const [dataSpecific, setDataSpecific] = useState({});

  const sendData = async () => {
    try {
      const response = await fetch(baseurl+`/whiskey_scatter`);
      const output = await response.json();
      setData(output.whiskey);
      console.log(output.whiskey);
    } catch (error) {
      console.log(error);
    };
  };

  async function postData(data = {}) {
    // Default options are marked with *
    const response = await fetch(baseurl+`/whiskey_specific`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    const output = await response.json(); // parses JSON response into native JavaScript objects
    setDataSpecific(output);
    console.log(output)
  }

  if (data.length < 2){
    sendData();
  }

  if (props.query && loaded === false){
    postData({whiskey:props.query});
    setLoaded(true);
  }

  if (redirect === true) {
    return <Redirect to="/Search2" />
  }

  return (
    <div>

    <Flex>
      <Box width={1} mt={10} mb={20} justifyContent='center' style={{borderWidth: '1px', borderStyle: 'solid', borderColor: 'black'}}>
        {data.length > 2 && (
          <div>
          <Autocomplete
            onSelect={e => setTempQuery(e.target.value)}
            id="combo-box-demo"
            options={data}
            autoComplete={true}
            autoHighlight={true}
            clearOnEscape={true}
            style={{ width:"100%"}}
            renderInput={params => (
              <TextField {...params} label="SEARCH FOR A WHISKEY" variant="filled" fullWidth />
            )}
          />
          </div>
        )}
      </Box>
    </Flex>

    <Button onClick={e => {props.setQuery(tempQuery); setLoaded(false); setRedirect(true)}} style={{height:"50px", width:"50%", margin:"0px", backgroundColor:'#022140', color:'white', fontFamily:'arial black', fontSize:20, borderWidth: '3px', borderStyle: 'solid', borderColor: 'black', borderRadius: '10px 10px 10px 10px'}}>Search</Button>

    {Object.entries(dataSpecific).length > 1 && (
      
      <div>

      <div>
      <Box sx={{my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
          <Flex backgroundColor="#022140">
            <Box  width={1} pl={2} py={2} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 16}}>
            {dataSpecific.stats[0]}
            </Box>
          </Flex>

          <Flex>
              <Box width={1}>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Type: "+dataSpecific.stats[4]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Brand: "+dataSpecific.stats[5]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Distillery: "+dataSpecific.stats[6]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Owner: "+dataSpecific.stats[7]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Number of Reviews: "+dataSpecific.stats[1]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Average Rating: "+dataSpecific.stats[2]}</Box>
                <Box as='hr' sx={{bg: 'gray', border: 0, height: 1}}/>
                <Box pl={2} py={2} textAlign="left" fontFamily="arial black" fontSize={16}>{"Standard Deviation: "+dataSpecific.stats[3]}</Box>
                
              </Box>
          </Flex>
        </Box>
      </div>

      <div>
        <Box sx={{pb:'5px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
          <Flex backgroundColor="#022140">
          <Box  width={1} pl={2} py={2} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 16}}>
              Top Flavors
            </Box>
          </Flex>
          <ListDesc words={dataSpecific.descriptive} />
        </Box>
      </div>
    
      <div>
      <Box sx={{pb:'5px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
        <Flex backgroundColor="#022140">
        <Box  width={1} pl={2} py={2} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 16}}>
              Profile
            </Box>
        </Flex>
      <Box px={1} pt={3}>
        <Bar x={dataSpecific.flavors} />
      </Box>
      </Box>
      </div>
    
      <div>
      <Box sx={{pb:'25px', my:'20px', backgroundColor:'#BDBDBD', borderWidth: '3px', borderStyle: 'solid', borderColor: 'black'}}>
        <Flex backgroundColor="#022140">
        <Box  width={1} pl={2} py={2} style={{textAlign:"left", fontFamily:"arial black", color:"white", fontSize: 16}}>
            Similar Whiskeys
          </Box>
        </Flex>
        <ListSims similars={dataSpecific.similars} setRedirect={setRedirect} setQuery={props.setQuery}/>
      </Box>
      </div>

    </div>
    
    )}

    </div>
    
  );
}